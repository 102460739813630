<template>
  <v-card
    min-height="100vh"
    outlined
    flat
    class="rounded-10 overflow-hidden support-home"
  >
    <v-card-title style="min-height: 250px" class="primary d-block rounded-0">
      <v-row dense align="center" justify="space-between">
        <v-breadcrumbs light :items="paths">
          <template v-slot:divider>
            <v-icon color="white">mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
              <span class="white--text">{{ item.text }}</span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-row>
      <v-row dense align="center" class="my-5" justify="center">
        <v-col
          cols="12"
          md="8"
          sm="10"
          class="white--text text-center fw-900 fs-30"
        >
          Get Help with {{ settings.SiteName }} Support
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-sheet
        outlined
        color="white"
        class="d-flex rounded-5 align-center mian-contennt justify-start flex-column mx-auto py-md-5"
        max-width="95%"
      >
        <strong class="primary--text fs-25 fw-700">Help & Support</strong>
        <v-row
          dense
          class="flex-wrap my-4 mx-md-5 mx-sm-1"
          align="start"
          justify="start"
        >
          <v-col cols="12" md="6" sm="12">
            <v-hover v-slot:default="{ hover }">
              <v-sheet
                :elevation="hover ? 3 : 0"
                height="200"
                class="rounded-5 ma-2 pa-5 d-flex align-center justify-start"
                outlined
              >
                <div class="d-flex col-md-3 pa-3 align-center justify-center">
                  <v-icon size="100">mdi-brain</v-icon>
                </div>
                <div class="d-flex align-stretch justify-center flex-column">
                  <strong class="primary--text mb-2 fs-20">
                    CRM Knowledge Base
                  </strong>
                  <p class="caption secondary--text">
                    Read our getting started guides and learn about more advance
                    features.
                  </p>
                  <div>
                    <v-btn
                      color="secondary"
                      outlined
                      small
                      class="my-2 text-none"
                    >
                      Browse Articles
                    </v-btn>
                  </div>
                </div>
              </v-sheet>
            </v-hover>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-hover v-slot:default="{ hover }">
              <v-sheet
                :elevation="hover ? 3 : 0"
                height="200"
                class="rounded-5 ma-2 pa-5 d-flex align-center justify-start"
                outlined
              >
                <div class="d-flex col-md-3 pa-3 align-center justify-center">
                  <v-icon size="100">mdi-account-group</v-icon>
                </div>
                <div class="d-flex align-stretch justify-center flex-column">
                  <strong class="primary--text mb-2 fs-20">Community</strong>
                  <p class="caption secondary--text">
                    Help us build a community around {{ settings.SiteName }},
                    get help from other {{ settings.SiteName }} users. We
                    actively participate on the forums.
                  </p>
                  <div>
                    <v-btn
                      color="secondary"
                      outlined
                      small
                      class="my-2 text-none"
                    >
                      View the community
                    </v-btn>
                  </div>
                </div>
              </v-sheet>
            </v-hover>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-hover v-slot:default="{ hover }">
              <v-sheet
                :elevation="hover ? 3 : 0"
                height="200"
                class="rounded-5 ma-2 pa-5 d-flex align-center justify-start"
                outlined
              >
                <div class="d-flex col-md-3 pa-3 align-center justify-center">
                  <v-icon size="100">mdi-head-lightbulb-outline</v-icon>
                </div>
                <div class="d-flex align-stretch justify-center flex-column">
                  <strong class="primary--text mb-2 fs-20">
                    Suggestions and Bug Report
                  </strong>
                  <p class="caption secondary--text">
                    Find existing feature suggestions and bug reports.
                  </p>
                  <div>
                    <v-btn
                      @click="$router.push({ name: 'support-tickets' })"
                      color="secondary"
                      outlined
                      small
                      class="my-2 text-none"
                    >
                      View suggestions and bugs reports
                    </v-btn>
                  </div>
                </div>
              </v-sheet>
            </v-hover>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-hover v-slot:default="{ hover }">
              <v-sheet
                :elevation="hover ? 3 : 0"
                height="200"
                class="rounded-5 ma-2 pa-5 d-flex align-center justify-start"
                outlined
              >
                <div class="d-flex col-md-3 pa-3 align-center justify-center">
                  <v-icon size="100">mdi-stethoscope</v-icon>
                </div>
                <div class="d-flex align-stretch justify-center flex-column">
                  <strong class="primary--text mb-2 fs-20">
                    System Status
                  </strong>
                  <p class="caption secondary--text">
                    Check the health status of our products and services.
                  </p>
                  <div>
                    <v-btn
                      color="secondary"
                      outlined
                      small
                      @click="$router.push({ name: 'support-system-status' })"
                      class="my-2 text-none"
                    >
                      View system status
                    </v-btn>
                  </div>
                </div>
              </v-sheet>
            </v-hover>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-hover v-slot:default="{ hover }">
              <v-sheet
                :elevation="hover ? 3 : 0"
                height="200"
                class="rounded-5 ma-2 pa-5 d-flex align-center justify-start"
                outlined
              >
                <div class="d-flex col-md-3 pa-3 align-center justify-center">
                  <v-icon size="100">mdi-head-question</v-icon>
                </div>
                <div class="d-flex align-stretch justify-center flex-column">
                  <strong class="primary--text mb-2 fs-20">
                    Billing, Subscription & Licensing
                  </strong>
                  <p class="caption secondary--text">
                    See FAQs about billing, subscriptions and licensing.
                  </p>
                  <div>
                    <v-btn
                      @click="$router.push({ name: 'support-faqs' })"
                      color="secondary"
                      outlined
                      small
                      class="my-2 text-none px-5"
                    >
                      View FAQs
                    </v-btn>
                  </div>
                </div>
              </v-sheet>
            </v-hover>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-hover v-slot:default="{ hover }">
              <v-sheet
                :elevation="hover ? 3 : 0"
                height="200"
                class="rounded-5 ma-2 d-flex align-center justify-start"
                outlined
              >
                <div class="d-flex col-md-3 pa-3 align-center justify-center">
                  <v-icon size="100">mdi-shield-crown</v-icon>
                </div>
                <div class="d-flex align-stretch justify-center flex-column">
                  <strong class="primary--text mb-2 fs-20">
                    Security Details
                  </strong>
                  <p class="caption secondary--text">
                    Review your permissions and privacy sharing to our services.
                  </p>
                  <div>
                    <v-btn
                      color="secondary"
                      outlined
                      small
                      @click="
                        $router.push({
                          name: 'support-securities',
                          params: { tab: 'privacy' }
                        })
                      "
                      class="my-2 mx-1 text-none"
                    >
                      Read Privacy Policy
                    </v-btn>
                    <v-btn
                      color="secondary"
                      outlined
                      small
                      @click="
                        $router.push({
                          name: 'support-securities',
                          params: { tab: 'terms' }
                        })
                      "
                      class="my-2 mx-1 text-none"
                    >
                      Read Terms of Use
                    </v-btn>
                  </div>
                </div>
              </v-sheet>
            </v-hover>
          </v-col>
        </v-row>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import store from '@/store/store'
import { mapActions } from 'vuex'
export default {
  components: {},
  data: () => ({
    paths: [
      {
        text: 'Dashboard',
        disabled: true,
        href: '/dashboard'
      },
      {
        text: 'Support',
        disabled: true,
        href: '/dashboard/support'
      }
    ]
  }),
  created() {
    //
  },
  methods: {
    ...mapActions('supports', ['submitTicket', 'fetchTags'])
  },
  beforeRouteEnter(to, from, next) {
    return store.dispatch('supports/fetchHandlers').then(next)
  }
}
</script>
<style lang="scss" scoped>
.mian-contennt {
  margin-top: -50px;
  min-height: 100vh;
}
</style>
