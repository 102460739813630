var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-10 overflow-hidden support-home",attrs:{"min-height":"100vh","outlined":"","flat":""}},[_c('v-card-title',{staticClass:"primary d-block rounded-0",staticStyle:{"min-height":"250px"}},[_c('v-row',{attrs:{"dense":"","align":"center","justify":"space-between"}},[_c('v-breadcrumbs',{attrs:{"light":"","items":_vm.paths},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-right")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"href":item.href,"disabled":item.disabled}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.text))])])]}}])})],1),_c('v-row',{staticClass:"my-5",attrs:{"dense":"","align":"center","justify":"center"}},[_c('v-col',{staticClass:"white--text text-center fw-900 fs-30",attrs:{"cols":"12","md":"8","sm":"10"}},[_vm._v(" Get Help with "+_vm._s(_vm.settings.SiteName)+" Support ")])],1)],1),_c('v-card-text',[_c('v-sheet',{staticClass:"d-flex rounded-5 align-center mian-contennt justify-start flex-column mx-auto py-md-5",attrs:{"outlined":"","color":"white","max-width":"95%"}},[_c('strong',{staticClass:"primary--text fs-25 fw-700"},[_vm._v("Help & Support")]),_c('v-row',{staticClass:"flex-wrap my-4 mx-md-5 mx-sm-1",attrs:{"dense":"","align":"start","justify":"start"}},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"rounded-5 ma-2 pa-5 d-flex align-center justify-start",attrs:{"elevation":hover ? 3 : 0,"height":"200","outlined":""}},[_c('div',{staticClass:"d-flex col-md-3 pa-3 align-center justify-center"},[_c('v-icon',{attrs:{"size":"100"}},[_vm._v("mdi-brain")])],1),_c('div',{staticClass:"d-flex align-stretch justify-center flex-column"},[_c('strong',{staticClass:"primary--text mb-2 fs-20"},[_vm._v(" CRM Knowledge Base ")]),_c('p',{staticClass:"caption secondary--text"},[_vm._v(" Read our getting started guides and learn about more advance features. ")]),_c('div',[_c('v-btn',{staticClass:"my-2 text-none",attrs:{"color":"secondary","outlined":"","small":""}},[_vm._v(" Browse Articles ")])],1)])])]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"rounded-5 ma-2 pa-5 d-flex align-center justify-start",attrs:{"elevation":hover ? 3 : 0,"height":"200","outlined":""}},[_c('div',{staticClass:"d-flex col-md-3 pa-3 align-center justify-center"},[_c('v-icon',{attrs:{"size":"100"}},[_vm._v("mdi-account-group")])],1),_c('div',{staticClass:"d-flex align-stretch justify-center flex-column"},[_c('strong',{staticClass:"primary--text mb-2 fs-20"},[_vm._v("Community")]),_c('p',{staticClass:"caption secondary--text"},[_vm._v(" Help us build a community around "+_vm._s(_vm.settings.SiteName)+", get help from other "+_vm._s(_vm.settings.SiteName)+" users. We actively participate on the forums. ")]),_c('div',[_c('v-btn',{staticClass:"my-2 text-none",attrs:{"color":"secondary","outlined":"","small":""}},[_vm._v(" View the community ")])],1)])])]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"rounded-5 ma-2 pa-5 d-flex align-center justify-start",attrs:{"elevation":hover ? 3 : 0,"height":"200","outlined":""}},[_c('div',{staticClass:"d-flex col-md-3 pa-3 align-center justify-center"},[_c('v-icon',{attrs:{"size":"100"}},[_vm._v("mdi-head-lightbulb-outline")])],1),_c('div',{staticClass:"d-flex align-stretch justify-center flex-column"},[_c('strong',{staticClass:"primary--text mb-2 fs-20"},[_vm._v(" Suggestions and Bug Report ")]),_c('p',{staticClass:"caption secondary--text"},[_vm._v(" Find existing feature suggestions and bug reports. ")]),_c('div',[_c('v-btn',{staticClass:"my-2 text-none",attrs:{"color":"secondary","outlined":"","small":""},on:{"click":function($event){return _vm.$router.push({ name: 'support-tickets' })}}},[_vm._v(" View suggestions and bugs reports ")])],1)])])]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"rounded-5 ma-2 pa-5 d-flex align-center justify-start",attrs:{"elevation":hover ? 3 : 0,"height":"200","outlined":""}},[_c('div',{staticClass:"d-flex col-md-3 pa-3 align-center justify-center"},[_c('v-icon',{attrs:{"size":"100"}},[_vm._v("mdi-stethoscope")])],1),_c('div',{staticClass:"d-flex align-stretch justify-center flex-column"},[_c('strong',{staticClass:"primary--text mb-2 fs-20"},[_vm._v(" System Status ")]),_c('p',{staticClass:"caption secondary--text"},[_vm._v(" Check the health status of our products and services. ")]),_c('div',[_c('v-btn',{staticClass:"my-2 text-none",attrs:{"color":"secondary","outlined":"","small":""},on:{"click":function($event){return _vm.$router.push({ name: 'support-system-status' })}}},[_vm._v(" View system status ")])],1)])])]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"rounded-5 ma-2 pa-5 d-flex align-center justify-start",attrs:{"elevation":hover ? 3 : 0,"height":"200","outlined":""}},[_c('div',{staticClass:"d-flex col-md-3 pa-3 align-center justify-center"},[_c('v-icon',{attrs:{"size":"100"}},[_vm._v("mdi-head-question")])],1),_c('div',{staticClass:"d-flex align-stretch justify-center flex-column"},[_c('strong',{staticClass:"primary--text mb-2 fs-20"},[_vm._v(" Billing, Subscription & Licensing ")]),_c('p',{staticClass:"caption secondary--text"},[_vm._v(" See FAQs about billing, subscriptions and licensing. ")]),_c('div',[_c('v-btn',{staticClass:"my-2 text-none px-5",attrs:{"color":"secondary","outlined":"","small":""},on:{"click":function($event){return _vm.$router.push({ name: 'support-faqs' })}}},[_vm._v(" View FAQs ")])],1)])])]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"rounded-5 ma-2 d-flex align-center justify-start",attrs:{"elevation":hover ? 3 : 0,"height":"200","outlined":""}},[_c('div',{staticClass:"d-flex col-md-3 pa-3 align-center justify-center"},[_c('v-icon',{attrs:{"size":"100"}},[_vm._v("mdi-shield-crown")])],1),_c('div',{staticClass:"d-flex align-stretch justify-center flex-column"},[_c('strong',{staticClass:"primary--text mb-2 fs-20"},[_vm._v(" Security Details ")]),_c('p',{staticClass:"caption secondary--text"},[_vm._v(" Review your permissions and privacy sharing to our services. ")]),_c('div',[_c('v-btn',{staticClass:"my-2 mx-1 text-none",attrs:{"color":"secondary","outlined":"","small":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'support-securities',
                        params: { tab: 'privacy' }
                      })}}},[_vm._v(" Read Privacy Policy ")]),_c('v-btn',{staticClass:"my-2 mx-1 text-none",attrs:{"color":"secondary","outlined":"","small":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'support-securities',
                        params: { tab: 'terms' }
                      })}}},[_vm._v(" Read Terms of Use ")])],1)])])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }